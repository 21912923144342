import { useTrans } from "locales/hook";
import EmptyTemplate from "components/EmptyTemplate";
import HLimitLongString from "components/LimitLongString";
import MoneyWithLocale from "components/Money/locale";
import StatusPeriod from "components/StatusPeriod";
import {
  GetComparison,
  GetCurrencyCode,
  formatNumber,
  linkTypeInTable,
  queryString,
  valueHandler,
} from "helpers/helpers";
import { useStore } from "pages/ReportStandard/context/store";
import { useEffect, useRef, useState } from "react";
import TableReportHeader from "../components/header";

function TableByDiscount(props: any) {
  const { canReadMACost, onHandleSortTable, reportNameSystem, summary } = props;
  const t = useTrans();
  const containerRef = useRef(null);
  const currencyCode = GetCurrencyCode();
  const { state } = useStore();
  const [renewSummary, setRenewSummary] = useState<any>([]);
  const [renewSummaryCompare, setRenewSummaryCompare] = useState<any>([]);
  const {
    // startdate,
    // enddate,
    summary_compare,

    statistical_table_data,
    sortValue,
  } = state;

  const shop: any = queryString.shop;

  const handleTableScroll = (e) => {
    let table = e.target.getElementsByTagName("table")[0];
    if (e.target.scrollLeft > 0) {
      table.classList.add("hrv-report-table-container__scrolling");
    } else {
      table.classList.remove("hrv-report-table-container__scrolling");
    }
  };
  useEffect(() => {
    const { current }: any = containerRef;
    current?.removeEventListener("scroll", handleTableScroll);
    current?.addEventListener("scroll", handleTableScroll);
    return () => {
      current?.removeEventListener("scroll", handleTableScroll);
    };
  }, [containerRef, statistical_table_data.data]);

  useEffect(() => {
    if (
      summary !== undefined &&
      summary.length &&
      summary_compare !== undefined &&
      summary_compare.length &&
      statistical_table_data.headerreport !== undefined &&
      statistical_table_data.headerreport.length
    ) {
      if (
        statistical_table_data.headerreport !== undefined &&
        summary !== undefined
      ) {
        const arrLength =
          statistical_table_data.headerreport.length - summary.length;
        if (arrLength > 0) {
          const reSummary =
            [
              ...Array(
                statistical_table_data.headerreport?.length - summary?.length
              ).fill(0),
              ...summary,
            ] || [];
          const reSummaryCompare =
            [
              ...Array(
                statistical_table_data.headerreport?.length -
                summary_compare?.length
              ).fill(0),
              ...summary_compare,
            ] || [];
          setRenewSummary(reSummary);
          setRenewSummaryCompare(reSummaryCompare);
        }
      }
    }
  }, [summary, statistical_table_data.headerreport, summary_compare]);

  const renderHeader = (headers, sortValue, sortable = true) => {
    return (
      <tr>
        {headers?.map((head, idx) => {
          let customClass = idx === 0 ? "hrv-report-first-column" : "";
          if (sortable) {
            customClass +=
              " hrv-report-table-column-sortable hrv-report-cursor-pointer ";
          }
          let name = head.fieldName;
          if (
            head.fieldName === "timeid" ||
            head.fieldName === "OrderDate" ||
            head.fieldName === "CreatedDate" ||
            head.fieldName === "TranDate" ||
            head.fieldName === "PackedDate"
          ) {
            name = head.name;
          }
          return (
            <th
              key={`table_header_${idx}`}
              className={`${customClass} ${head.dataFormat === "money" ||
                head.dataFormat === "number" ||
                head.dataFormat === "percent"
                ? "hrv-report-text-right"
                : "hrv-report-text-left"
                }`}
              onClick={() => {
                sortable && onHandleSortTable && onHandleSortTable(head.name);
              }}
            >
              <TableReportHeader
                reportNameSystem={reportNameSystem}
                name={name}
              />

              {sortable && (
                <span className={`hrv-report-ml-4 hrv-report-cursor-pointer`}>
                  {sortValue.dimension !== head.name ? (
                    <>{svgDoubleArrow}</>
                  ) : (
                    <span
                      className={`${sortValue.direction === "desc" && "hrv-report-desc"
                        }`}
                    >
                      {svgArrowDown}
                    </span>
                  )}
                </span>
              )}
            </th>
          );
        })}
      </tr>
    );
  };

  let dataHeaders = [...(statistical_table_data.headerreport || [])];
  let headers = statistical_table_data.headerreport || [];
  let dataRows = statistical_table_data.data || [];
  let dataLink = statistical_table_data.datalink;
  let summaryRow = false;
  renewSummary.forEach(s => {
    if (s !== 0) {
      if (Math.abs(Number(s.value)) !== 0) {
        summaryRow = true;
      }
    }
  });
  if (!summaryRow) {
    dataHeaders = [];
    headers = [];
  }

  let headingRows: Array<any> = [];
  let subHeadingRows: Array<any> = [];

  return (
    <div
      id="hrv-report-table-container"
      style={{
        borderLeft: 0,
        borderRight: 0,
      }}
    >
      <div>
        <div className={`hrv-report-table-container`} ref={containerRef}>
          <table className="">
            <thead>{renderHeader(dataHeaders, sortValue, true)}</thead>

            <tbody>
              {summaryRow && !!summary?.length && (
                <tr>
                  {renewSummary?.map((sum, id) => {
                    const format = sum.dataFormat;
                    const name = sum.name;
                    const value = sum.value;
                    const firstColumn =
                      id === 0 ? "hrv-report-first-column" : "";
                    let comparison = GetComparison(
                      value,
                      renewSummaryCompare[id].value
                    );
                    if (["DiscountValue", "RedeemValue", "DiscountValueInPercent"].includes(name)) {
                      comparison.value = Math.abs(comparison.value);
                    }

                    return (
                      <td
                        key={`table_tr_sum_td_${id}`}
                        className={`hrv-report-fw-500 ${firstColumn} ${format === "money" ||
                          format === "number" ||
                          format === "percent"
                          ? "hrv-report-text-right"
                          : "hrv-report-text-left"
                          }`}
                      >
                        {sum !== 0 ? (
                          <div>
                            <div>
                              {format === "money" ? (
                                <MoneyWithLocale
                                  value={value}
                                  currencyCode={currencyCode}
                                  className="hrv-report-text-right"
                                />
                              ) : (
                                valueHandler(format, name, value)
                              )}
                            </div>
                            <div className="hrv-report-text-color-gray-9 hrv-report-fw-400">
                              {format === "money" ? (
                                <MoneyWithLocale
                                  value={renewSummaryCompare[id].value}
                                  currencyCode={currencyCode}
                                  className="hrv-report-text-right"
                                />
                              ) : (
                                valueHandler(
                                  format,
                                  name,
                                  renewSummaryCompare[id].value
                                )
                              )}
                            </div>
                            <div>
                              <StatusPeriod
                                value={formatNumber(comparison.value)}
                                trend={comparison.trend}
                                infinity={comparison.infinity}
                                reverse={comparison.reverse}
                                className="hrv-report-justify-end"
                              />
                            </div>
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              )}
              {headers.length > 0 &&
                dataRows?.map((body, idx) => {
                  let trClass = "";
                  if (headingRows.includes(idx)) {
                    trClass = "hrv-report-fw-500 hrv-report-text-color-black-6";
                  }

                  return (
                    <tr key={`tr_table_r_${idx}`} className={trClass}>
                      {body.map((value, id) => {
                        const format = headers[id]?.dataFormat || "text";
                        const name = headers[id]?.name || "";
                        if (
                          ["CosAmountOpening", "CosAmountClosing"].includes(
                            name
                          ) &&
                          !canReadMACost
                        ) {
                          return <></>;
                        }
                        let firstColumn =
                          id === 0 ? "hrv-report-first-column" : "";
                        if (subHeadingRows.includes(idx) && id === 0) {
                          firstColumn += " hrv-report-pl-32";
                        }
                        let link = "";
                        let isLink = false;
                        if (format === "link") {
                          isLink = true;
                          link = `https://${shop}/admin/${linkTypeInTable(
                            name
                          )}/${dataLink[idx][id]}`;
                          if (dataLink[idx][id] === 0) {
                            isLink = false;
                            if (name === "ProductId" || name === "VariantId") {
                              if (value === "--") {
                                value = "Sản phẩm tùy chọn";
                              } else {
                                value = t(value.trim());
                              }
                            }
                          }
                          if (value === "--") {
                            isLink = false;
                          }
                        }
                        if (name === "Status") {
                          value = t(value.trim());
                        }

                        return (
                          <td
                            key={`td_table_r_${idx}_${id}`}
                            className={firstColumn}
                          >
                            <div>
                              {format === "link" ? (
                                <HLimitLongString
                                  text={value}
                                  customClass={`hrv-report-text-left hrv-report-mb-0 hrv-report-fs-14 hrv-report-table-col-pre ${isLink
                                    ? "hrv-report-text-color-blue-2 hrv-report-cursor-pointer"
                                    : ""
                                    }`}
                                  isLink={isLink}
                                  link={link}
                                  lengthText={30}
                                />
                              ) : format === "money" ? (
                                <MoneyWithLocale
                                  value={value}
                                  currencyCode={currencyCode}
                                  className="hrv-report-text-right"
                                />
                              ) : (
                                <HLimitLongString
                                  customClass={`hrv-report-mb-0 hrv-report-table-col-pre ${format === "money" ||
                                    format === "number" ||
                                    format === "percent"
                                    ? "hrv-report-text-right"
                                    : "hrv-report-text-left"
                                    }`}
                                  text={valueHandler(format, name, value)}
                                  isLink={isLink}
                                  lengthText={30}
                                />
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {headers.length === 0 && (
            <EmptyTemplate
              text={t("no_data")}
              subText={t("change_fitler_hint")}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TableByDiscount;

const svgDoubleArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1583_19978)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1138 8L10.0004 5.88667L7.8871 8L6.94043 7.06L10.0004 4L13.0538 7.06L12.1138 8ZM7.8871 12L10.0004 14.1133L12.1138 12L13.0604 12.94L10.0004 16L6.9471 12.94L7.8871 12Z"
        fill="#9CA3B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1583_19978">
        <rect width="20" height="20" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const svgArrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.9997 12.5001L6.46387 8.96422L7.64303 7.78589L9.9997 10.1434L12.3564 7.78589L13.5355 8.96422L9.9997 12.5001Z"
      fill="#2463EB"
    />
  </svg>
);
