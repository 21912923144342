// ! Các báo cáo liên quan đến bán hàng
export const SALES_METRICS = [
    'sales_transaction_by_month', // Báo cáo doanh thu theo tháng hiện tại
    "sales_order_assigned_location", // Theo kho xuất
    "sales_orders_location", // Chi nhánh bán hàng
    "sales_orders_customer", // Theo khách hàng
    "sales_orders_product_itemtype", // Sản phẩm - nhà sản xuất
    "sales_orders_product",// Theo sản phẩm
    "sales_orders_salechannel", // Kênh bán hàng
    "sales_orders_salechannel_pos", // Kênh POS
    "sales_orders_salechannel_facebook", // Kênh facebook
    'sales_orders_trafficsource', // traffic source
    "sales_orders_province", // Bán theo vùng
    "sales_orders_sale_day", // Nhân viên bán hàng - theo ngày
    "sales_transaction_by_hours", // Theo ngày giờ
    "sales_orders_product_sku", // Sản phẩm SKU
    "sales_payments_according", // Theo Doanh thu thuần (tài chính)
    "sales_payments_overview", // Tài chính tổng quan (tài chính)
    "customers_by_age", // Theo nhóm tuổi
    "customers_by_gender", // theo giới tính
    "order_shipments_package", // Vận đơn đóng gói 1
];

// ! Các báo cáo khác loại báo cáo bán hàng
export const METRICS: any = [
    "sales_overview_promotion", // * Báo cáo hiệu quả khuyến mãi (producton)
    "customers_new_and_return", // * Báo cáo khách mới khách cũ (testing)
    'customers_by_location', // * Theo vị trí địa lí (production)
    "customers_by_rfm",
    "sales_orders_staff", // Nhân viên bán hàng
    "order_shipments_detail_carriers_internal_speed", // * Chi tiết - Tốc độ xử lý đơn nội bộ
    "order_shipments_overview_carriers_internal_speed", // * Tổng quan - Tốc độ xử lý đơn nội bộ
    "sales_orders_salechannel_branch", // * Báo cáo sàn thương mại điện tử
    "web_pageviews_month", // * Báo cáo Pageviews
]

// ! Các báo cáo có sử dụng tính năng so sánh theo kỳ
export const REPORT_OPEN_COMPARE = ["sales_promotion", 'sales_overview_promotion'];

// ! Chỉ số được vẽ theo số âm nhưng hiển thị theo chiều từ trái sang phải
export const REVERSE_NEGATIVE_FIELD_NAME = [
    "DiscountAmount",
    "ReturnsAmount",
    "TotalDiscount"
]

export const LIMIT_GROUPED_BY_DISCOUNT = 10;

// ! Một số report sẽ vẽ theo trục Y (Cột ngang) với report được định nghĩa 
// ! Chọn loại là thời gian thì cột sẽ vẽ theo chiều dọc
// ! Những chart bên trong mảng này sẽ không apply logic trên (phủ định)
export const DRAW_BY_Y_AXIS_FOR_SPECIFIC_REPORTS = [
    "sales_overview_promotion",
    "sales_orders_staff"
];


// ! Những report được phép thay đổi loại chart hiển thị trong detail
export const ALLOW_CHANGE_DISPLAY_CHART = [
    'sales_overview_promotion',
    "sales_orders_staff"
];

export const SAMPLE_COLORS = {
    ["Tiktok Shop"]: "#F00044",
    Shopee: "#F97415",
    Lazada: "#0E146A",
    Staff: "#14B8A5",
    Harasocial: "#03A9F4",
    POS: "#4CAF50",
    Phone: "#EC4699",
    Web: "#3C83F6",
    Facebook: "#0865FE",
    Zalo: "#07B6D5",
    Draft: "#00579B",
    Sendo: "#BC0105",
    Tiki: "#51CBFF",
    "Google Shop": "#FBBC05",
    Harafunnel: "#6366F2",
    "Facebook Shop": "#F59F0A",
    "App Android": "#A855F7",
    "App IOS": "#6B7280",
    HaraPOS: "#82CB15",
    Livestream: "#811D1D",
    Instagram: "#6C49CB",
    'Khách vãng lai': "#D1D5DB",
    'Khách cũ': "#3C83F6",
    'Khách mới': "#F97415",
};

export const TIMESTAMP_OBJECT = ["Date", "Hour", "Day", "Month", "Year"];