import { useTrans } from "locales/hook";

export default function HeaderTooltips(props) {
  const { reportNameSystem } = props;
  const t = useTrans();

  function createMapping(keys) {
    const mappings = {};

    keys.forEach(key => {
      mappings[key] = t(`${key}_Desc`);
    });

    return mappings;
  }

  // Key for translate (Thêm 1 nameField tại đây sẽ tạo ra 1 description tương ứng namefield đó)
  const keys = [
    "Qty_OpeningStock", "CosAmountOpening", "Qty_Init_In", "Qty_Transfer_In", "Qty_Adjustment_In",
    "Qty_Adjustment_Out", "Qty_PurchaseOrder_In", "Qty_SaleOrderRestock_In",
    "Qty_Transfer_Out", "Qty_SaleOrderFulfill_Out", "Qty_PurchaseOrderReturn_Out",
    "Qty_ClosingStock", "Quantity", "QuantityWithPromotion", "ReturnQuantity",
    "CosAmountClosing", "GrossSaleAmount", "GrossSaleAmountWithPromotion",
    "DiscountAmount", "RedeemValue", "ReturnsAmount", "NetSaleAmount",
    "ShippingAmount", "TotalSaleAmount", "PaidAmount", "MACostAmount", "Profit",
    "ProfitMargin", "DiscountValue", "DiscountValueInPercent",
    "CustomerCountSpecial", "CustomerCountSpecialWithPromotion", "OrderCountSpecial",
    "OrderCountSpecialWithPromotion", "NetSaleAmountAfterDiscount", "AvgOrderValue",
    "AvgQuantityPerOrder", "FulfillmentCount", "ReadyToPickToDelivered",
    "ReadyToPickToHandOver", "HandOverToDelivered", "RateSuccess", "RateReturn",
    "RealShippingFee", "CarrierReadyToPick", "CarrierDelivering", "CarrierDelivered",
    "CarrierWaitingForReturn", "CarrierReturn", "ActuallyReceived",
    "OrderConfirmedCount", "OrderToConfirmedTimeSpan", "OrderAssignedLocationCount",
    "ConfirmedToAssignedLocationTimeSpan", "AssignedLocationToInstockConfirmedTimeSpan",
    "OrderInstockConfirmedCount", "OrderExportCount", "InstockConfirmedToExportTimeSpan",
    "ShipmentCount", "ShipmentHasConfirmedCount", "ExportToShipmentTimeSpan",
    "HandOverCount", "ShipmentToHandOverTimeSpan", "TotalProcessingTimeSpan",
    "ConfirmedToShipmentTimeSpan", "OrderToShipmentTimeSpan", "DiscountProductValue",
    "DiscountShippingValue", "TotalProcessingAdvanceTimeSpan",
    "TotalProcessingStandardTimeSpan", "Qty_SoldPerDay", "Qty_DayInventoryRemaining",
    "SellerDiscountAmount", "ChannelDiscount", "SellerDiscount", "ShippingExpense",
    "PlatformFee", "PaymentFee", "ServiceFee", "OtherDeductions", "RealSellerIncome",
    "ShippingExpenseAmount", "OtherDeductionsAmount", "SellerIncome",
    "ProductDiscountAmount", "NetSaleAmountWithSaleChannel", "CarrierCancel",
    "DiscountShippingAmount", "DiscountAmountInPercent", "OriginalPrice",
    "ReceiveQty", "ReceiveCostAmount", "ReturnQty", "ReturnCostAmount",
    "ReturnDiscountAmount", "TotalReturnAmount", "ReturnQtyPercent",
    "ActuallyReceivedQty", "TotalAmount", "CostPriceReference",
    "ReceiveDiscountAmount", "ReceiveOtherExpensesNCCAmount",
    "ReceiveOtherExpensesAmount", "TotalReceiveAmount",
    "ReturnOtherExpensesNCCAmount", "ReturnOtherExpensesAmount",
    "NetSaleAmountByLocAssigned", "OrderDiscountAmount",
    "TaxNotIncludedProductAmount", "TaxIncludedProductAmount",
    "TotalTaxAmount", "NetSaleAmountNotIncluedTax",
    "NetSaleAmountIncluedTax", "TotalNetSaleAmount",
    "ProfitAfterTax", "NetSaleAmountBeforeTax",
    "NetSaleAmountAfterTax", "CustomerType",
    "GrossMerchandiseValueAmount", "AverageOrderValueAmount",
    "GrossProfitAmount", "GrossProfitPercent",
    "ShippingTaxNotIncludedAmount", "ShippingTaxIncludedAmount",
    "TotalDiscount", "TotalChannelFee", "CustomerCountSpecialWithRFM", "DayDiffPurchaseWithRFM", "OrderCountSpecialWithRFM", "NetSaleAmountWithRFM",
    "AverageOrderValueAmountWithRFM",
    "ProfitByLocassigned",
    "ProfitMarginByLocassigned",
    "QuantityWithPackage",
    "OrderCountSpecialWithPackage"
  ];

  // Create the mappings
  const mappings = createMapping(keys);

  const getNameWithContext = (name) => {
    if (["sales_promotion", "sales_overview_promotion"].includes(reportNameSystem)) {
      if (name === "Quantity") {
        name = "QuantityWithPromotion";
      }
      if (name === "CustomerCountSpecial") {
        name = "CustomerCountSpecialWithPromotion";
      }
      if (name === "OrderCountSpecial") {
        name = "OrderCountSpecialWithPromotion";
      }
      if (name === "GrossSaleAmount") {
        name = "GrossSaleAmountWithPromotion";
      }
    }

    if (reportNameSystem === "sales_orders_salechannel_branch") {
      if (name === "NetSaleAmount") {
        name = "NetSaleAmountWithSaleChannel";
      }
    }

    if (reportNameSystem === "customers_by_rfm") {
      if (name === "CustomerCountSpecial") {
        name = "CustomerCountSpecialWithRFM"
      }

      if (name === "DayDiffPurchase") {
        name = "DayDiffPurchaseWithRFM"
      }

      if (name === "OrderCountSpecial") {
        name = "OrderCountSpecialWithRFM"
      }

      if (name === "NetSaleAmount") {
        name = "NetSaleAmountWithRFM"
      }

      if (name === "AverageOrderValueAmount") {
        name = "AverageOrderValueAmountWithRFM"
      }
    }

    if (reportNameSystem === "order_shipments_package") {
      if (name === 'Quantity') {
        name = 'QuantityWithPackage'
      }
      if (name === 'OrderCountSpecial') {
        name = 'OrderCountSpecialWithPackage'
      }
    }

    return name;
  };

  const has = (name) => {
    name = getNameWithContext(name);
    return mappings.hasOwnProperty(name);
  };

  const get = (name) => {
    name = getNameWithContext(name);
    return mappings[name];
  };

  const getName = (name) => {
    name = getNameWithContext(name);
    return name
  }

  return {
    has,
    get,
    getName
  };
}
