import dayjs from "components/Dayjs";

// * Output momentjs to dayjs object
// export const o_momentToDayjs = (momentObject) => {
//     return dayjs(momentObject);
// }

// * Do option Quý này và Năm này (enddate có thể là ngày kết thúc hoặc enddate có thể là ngày hiện tại)
let endDateQuaterCurrent = dayjs().endOf("quarter");
let endDateYearCurrent = dayjs().endOf("year");

// * Định nghĩa range chung cho datepicker
export const DATE_RANGES_DEFAULT = (t) => {
    return {
        [t("today")]: [dayjs(), dayjs()],
        [t("yesterday")]: [
            dayjs().subtract(1, "days"),
            dayjs().subtract(1, "days"),
        ],
        [t("sevenDaysAgo")]: [
            dayjs().subtract(6, "days"),
            dayjs()
        ],
        [t("thirtyDaysAgo")]: [
            dayjs().subtract(29, "days"),
            dayjs()
        ],
        [t("thisMonth")]: [
            dayjs().startOf("month"),
            dayjs().endOf('month')
        ],
        [t("lastMonth")]: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
        ],
        [t("current_quarter")]: [
            dayjs().startOf("quarter"),
            endDateQuaterCurrent,
        ],
        [t("previous_quarter")]: [
            dayjs().quarter(dayjs().quarter() - 1)
                .startOf('quarter'),
            dayjs().quarter(dayjs().quarter() - 1)
                .endOf('quarter')
        ],
        [t("current_year")]: [
            dayjs().startOf("year"),
            endDateYearCurrent,
        ],
        [t("previous_year")]: [
            dayjs().subtract(1, 'year').startOf('year'),
            dayjs().subtract(1, 'year').endOf('year')
        ],
    };
}

export const DATE_RANGES_STOCK_LOTS = (t) => {
    return {
        [t("expired")]: [
            (dayjs(new Date(2022, 6, 1))),
            (dayjs().subtract(1, "days"))
        ],
        [t("valid")]: [
            (dayjs()),
            (dayjs().add(dayjs.duration({ 'years': 6 })))
        ],
        [t("valid_today")]: [
            (dayjs()),
            (dayjs())
        ],
        [t("valid_nextSevenDays")]: [
            (dayjs()),
            (dayjs().add(dayjs.duration({ 'days': 7 })))
        ],
        [t("valid_thisMonth")]: [(dayjs()), (dayjs().endOf("month"))],
        [t("valid_nextMonth")]: [
            (dayjs()),
            (dayjs().add(dayjs.duration({ 'months': 1 }))),
        ],
        [t("valid_nextThreeMonths")]: [
            (dayjs()),
            (dayjs().add(dayjs.duration({ 'months': 3 }))),
        ],
        [t("valid_nextSixMonths")]: [
            (dayjs()),
            (dayjs().add(dayjs.duration({ 'months': 6 }))),
        ],
    }
}

export const DATE_RANGES_RFM = (t) => {
    return {
        [t("last_365_days_ago")]: [
            (dayjs().subtract(365, "days")),
            (dayjs())
        ],
        [t("last_180_days_ago")]: [
            (dayjs().subtract(180, "days")),
            (dayjs())
        ],
        [t("last_90_days_ago")]: [
            (dayjs().subtract(90, "days")),
            (dayjs())
        ],
        [t("year_to_date")]: [
            dayjs().startOf("year"),
            dayjs()
        ],

    }
}