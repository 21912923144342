import { memo } from "react";
import useWindowDimensions from "hooks/useWindowDimension";

import TableReportHeader from "components/Table/components/header";

import { alignmentText } from "helpers/helpers";
import { FIXED_HEATMAP_WIDTH_CELL } from "pages/ReportStandard/context/store/constants";

function DefaultHeader(props) {
    const {
        dataHeaders,
        sortValue,
        sortable = true,
        reportNameSystem,
        heatmapMode,
        onHandleSortTable,
    } = props;

    const { width } = useWindowDimensions();


    const handleSortTable = (value) => {
        onHandleSortTable && onHandleSortTable(value);
    };

    return (
        <tr>
            {dataHeaders?.map((head, idx) => {
                let customClass = idx === 0 ? "hrv-report-first-column" : "";

                if (sortable) {
                    customClass +=
                        " hrv-report-table-column-sortable hrv-report-cursor-pointer ";
                }

                if (width < 650 && idx === 0) {
                    customClass += " hrv-report-first-column-responsive"
                }

                let name = head.fieldName;
                if (
                    head.fieldName === "timeid" ||
                    head.fieldName === "OrderDate" ||
                    head.fieldName === "CreatedDate" ||
                    head.fieldName === "TranDate" ||
                    head.fieldName === "PackedDate"
                ) {
                    name = head.name;
                }

                return (
                    <th
                        key={`table_header_${idx}`}
                        className={`${customClass} ${alignmentText(head.dataFormat)}`}
                        onClick={() => {
                            sortable && handleSortTable(head.name);
                        }}
                        attr-name={name}
                    >
                        {heatmapMode ? (
                            <div
                                className="hrv-report-text-left hrv-report-d-flex hrv-report-items-center"
                                style={{
                                    minWidth: FIXED_HEATMAP_WIDTH_CELL[head.fieldName] || "unset",
                                    maxWidth: FIXED_HEATMAP_WIDTH_CELL[head.fieldName] || "unset",
                                }}
                            >
                                <span>
                                    <TableReportHeader
                                        reportNameSystem={reportNameSystem}
                                        name={name}
                                    />
                                </span>

                                {sortable && (
                                    <span className={`hrv-report-ml-4 hrv-report-cursor-pointer`}>
                                        {sortValue.dimension !== head.name ? (
                                            <>{svgDoubleArrow}</>
                                        ) : (
                                            <span
                                                className={`${sortValue.direction === "desc"
                                                    ? "hrv-report-desc"
                                                    : "hrv-report-inc"
                                                    }`}
                                            >
                                                {svgArrowDown}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <>
                                <TableReportHeader
                                    reportNameSystem={reportNameSystem}
                                    name={name}
                                />
                                {sortable && (
                                    <span className={`hrv-report-ml-4 hrv-report-cursor-pointer`}>
                                        {sortValue.dimension !== head.name ? (
                                            <>{svgDoubleArrow}</>
                                        ) : (
                                            <span
                                                className={`${sortValue.direction === "desc"
                                                    ? "hrv-report-desc"
                                                    : "hrv-report-inc"
                                                    }`}
                                            >
                                                {svgArrowDown}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </>
                        )}
                    </th>
                );
            })}
        </tr>
    );
}

export default memo(DefaultHeader);

const svgDoubleArrow = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <g clipPath="url(#clip0_1583_19978)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1138 8L10.0004 5.88667L7.8871 8L6.94043 7.06L10.0004 4L13.0538 7.06L12.1138 8ZM7.8871 12L10.0004 14.1133L12.1138 12L13.0604 12.94L10.0004 16L6.9471 12.94L7.8871 12Z"
                fill="#9CA3B0"
            />
        </g>
        <defs>
            <clipPath id="clip0_1583_19978">
                <rect width="20" height="20" rx="2" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const svgArrowDown = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M9.9997 12.5001L6.46387 8.96422L7.64303 7.78589L9.9997 10.1434L12.3564 7.78589L13.5355 8.96422L9.9997 12.5001Z"
            fill="#2463EB"
        />
    </svg>
);
